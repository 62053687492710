.user-detail-container {
    margin: 100px 0px 0px 25%;
}

.user-detail-upper {
    margin: 10px;
    display: flex;
    align-items: center;
}

.user-detail-user-info {
    margin-left: 5px;
}

.user-detail-user-info p {
    margin: 5px;
}

.user-detail-name {
    font-weight: 700;
    font-size: 36px;
}

.user-detail-bottom {
    font-size: 18px;
}


.user-detail-profile-pic {
    border: 1px solid black;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}
