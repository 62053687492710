.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.nav-bar {
  border-bottom: 2px solid black;
  background-color: snow;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 79%;
}

.nav-bar .logo {
  width: 300px;
}
