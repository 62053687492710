.signup-form-container {
    margin: 20px;
}

.signup-form-container h1 {
    text-align: center;
}

.signup-form-container label {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0px;
    width: 300px;
}

.signup-button {
    background-color: black;
    color: white;
    width: 100%;
    height: 30px;
    border-radius: 25px;
    margin-top: 10px;
}

.signup-button:hover {
    cursor: pointer;
}

.signup-form-container p {
    color: red;
}
