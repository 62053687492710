.likes-container {
    display: flex;
}

.likes-container button .like-thumb {
    padding-left: 7px;
}

.likes-counter {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: 700;
}
