#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: snow;
  border: 3px solid black;
  border-radius: 5px;
  max-width: 90%;
  max-height: 80%;
  overflow: auto;
  padding: 20px 20px 15px;
}
