.comment-modal-title {
    text-align: center;
}

.comment-modal-form {
    margin: 20px;
}

.comment-modal-input {
    border: 1px solid black;
    width: 98%;
    padding: 5px;
    margin: 10px 0px;
}

.comment-modal-textarea {
    background-color: transparent;
    width: 98%;
    padding: 5px;
    height: 50px;
}

.comment-modal-button {
    background-color: black;
    color: white;
    width: 100%;
    height: 30px;
    border-radius: 25px;
    margin-top: 10px;
}

.comment-modal-button:hover {
    cursor: pointer;
}

.comment-modal-error {
    text-align: center;
}
