.post-details-container {
    border: 2px solid black;
    margin: 20px 10px;
    /* width: 60%; */
    /* padding: 15px; */
    /* margin-top: 100px; */
}

/* .post-details-container div {
    margin: 10px;
} */

.post-profile-pic {
    border: 1px solid black;
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.post-user-date p {
    /* border: 1px solid red; */
    margin: 5px;
    display: flex;
    align-items: center;
}

.post-username {
    /* border: 1px solid red; */
    font-size: 24px;
    font-weight: 700;
}

.post-user-button {
    border: 1px solid white;
    display: grid;
    gap: 5px;
    height: 32px;
}

.post-user-buttons button {
    /* border: 1px solid red; */
    border: transparent;
    color: gray;
    background-color: transparent;
    padding: 0px;
    margin-top: 10px;
    height: 16px;
}

.post-user-buttons button:hover {
    cursor: pointer;
    color: red;
}

.post-created {
    font-size: 12px;
}

.post-upper-details {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.post-user-details {
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    gap: 10px;
}

.post-body {
    /* border: 1px solid blue; */
    margin: 20px 0px;
    margin-left: 10%;
    width: 80%;
    overflow-wrap: break-word;
}

.post-pics {
    border: none;
    margin-left: 10%;
    width: 80%;
    height: 100%;
    display: flex;
    gap: 15px;
    box-shadow: 4px 4px 8px black;
}

.post-pics button {
    border: none;
    background-color: snow;
    padding: 0px;
    cursor: pointer;
    height: auto;
    width: 100%;
}

.post-pics button img {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
}

#modal-content img {
    /* border: 1px solid green; */
    height: 100%;
    width: 100%;
}

.post-postComments {
    /* border: 1px solid red; */
    margin: 20px;
}

.post-comment-input form {
    /* border: 1px solid red; */
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 20px;
}

.post-comment-input textarea {
    border: 1px solid black;
    font-size: 14px;
    font-weight: 700;
    min-height: 50px;
    height: fit-content;
    box-shadow: 2px 2px 4px black;
    background: transparent;
    width: 99%;
    padding: 5px;
}

.post-comment-input button {
    height: 36px;
    border-radius: 25px;
    font-size: 16px;
    color: white;
    background-color: black;
}

.post-comment-input button:hover {
    cursor: pointer;
}

.post-comment-bottom {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.post-comment-bottom button {
    /* border: 1px solid red; */
    width: 150px;
}

.like-counter {
    height: 36px;
    border-radius: 25px;
    font-size: 16px;
    color: white;
    background-color: black;
    width: 150px;
    margin: 0px 0px 20px 20px;
}

.space {
    margin-left: 10px;
}
