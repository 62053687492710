.sideBar {
    border-right: 2px solid black;
    width: 12%;
    height: 100%;
    position: fixed;
    top: 80px;
    padding: 20px 20px;
}

.sideBar div {
    margin: 13px 0px;
}

.sideBar-font {
    /* border: 1px solid red; */
    font-weight: 700;
    display: flex;
    align-items: center;
}

.sideBar-font i {
    font-weight: 700;
    margin-right: 10px;
}

.sidebar-logout {
    display: flex;
    font-weight: 700;
    text-align: center;
    margin: 0px 0px 10px 0px;
}

.sidebar-logout i {
    margin: 0px 12px 0px 3px;
}

.sidebar-links:hover {
    cursor: pointer;
    max-width: auto;
}

.create-post-modal button {
    background-color: black;
    border-radius: 25px;
    color: white;
    box-shadow: none;
    padding: 15px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.create-post-button-text i {
    margin-right: 10px;
}

.create-post-modal button:hover {
    cursor: pointer;
}

.sidebar-login-signup {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.sidebar-login-signup button {
    border: transparent;
    background-color: snow;
    padding: 0px;
    font-weight: 700;
    font-size: 16px;
}

.sidebar-login-signup button:hover {
    cursor: pointer;
}

.sidebar-login-signup i {
    padding-right: 10px;
}

.about-links-container {
    position: fixed;
    bottom: 1%;
    display: flex;
    flex-direction: column;
    text-align: start;
    font-weight: 700;
}

.about-links-container a {
    color: inherit;
    text-decoration: none;
}

.gap {
    margin-right: 10px;
}

@media screen and (max-device-width: 700px) {
    .sideBar {
        border-right: 0;
        border-bottom: 2px solid black;
        /* width: 12%; */
        /* height: 100%; */
        /* position: fixed; */
        /* top: 80px; */
        /* padding: 20px 20px; */

        /* border: 1px solid red; */
        width: 96%;
        height: 50px;
        display: flex;
        flex-direction: column;
        position: fixed;
        background-color: snow;
        padding: 0;
    }

    .sideBar div {
        margin: 0;
    }

    .upper-sideBar {
        /* border: 1px solid blue; */
        display: flex;
        height: 100%;
        width: 100%;
    }

    .sideBar-home {
        /* border: 1px solid orange; */
        display: flex;
        justify-content: center;
        width: 33%;
    }

    .user-sideBar {
        /* border: 1px solid green; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        gap: 10px;
        width: 67%;
    }

    .sidebar-links {
        margin: 0;
    }

    .create-post-modal button {
        /* border: 1px solid red; */
        border-radius: 0px;
        height: 0;
    }

    .sidebar-login-signup {
        /* border: 1px solid green; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        gap: 10px;
        width: 67%;
    }

    .about-links-container .link {
        /* border: 1px solid red; */
        margin: 10px 0px;
    }
}
